package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.helpers.esg.GenericSection3n3TitlesEnum as Titles
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import react.dom.html.OlType
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange
import web.cssom.ClassName
import web.html.HTMLDivElement
import web.html.HTMLElement


external interface GenericSection3n3Props : Props {
    var onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit
    var onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit
    var materialTopicName: String?
    var section3_3_A1_name: String?
    var section3_3_A1_value: String?
    var section3_3_A2_name: String?
    var section3_3_A2_value: String?
    var section3_3_A3_name: String?
    var section3_3_A3_value: String?

    var section3_3_A4_name: String?
    var section3_3_A4_value: String?

    var section3_3_A5_name: String?
    var section3_3_A5_value: String?

    var section3_3_A6_name: String?
    var section3_3_A6_value: String?
}

val GenericSection_3_3 = FC<GenericSection3n3Props> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea


    SectionMainTitle {
        title = "3-3 MANAGEMENT OF MATERIAL TOPICS"
    }
    SectionSubHeading {
        subHeading = "Material Topic: ${props.materialTopicName}"
    }


    Box {
        label {
            +Titles.TITLE_1.titleDescription
        }
        ESGTextAreaFieldWrapper {
            name = props.section3_3_A1_name
            value = props.section3_3_A1_value
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }

    Box {
        label {
            +Titles.TITLE_2.titleDescription
        }
        ESGTextAreaFieldWrapper {
            name = props.section3_3_A2_name
            value = props.section3_3_A2_value
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }

    Box {
        label {
            +Titles.TITLE_3.titleDescription
        }
        ESGTextAreaFieldWrapper {
            name = props.section3_3_A3_name
            value = props.section3_3_A3_value
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }



    Box {
        Titles.TITLE_4?.listTitles?.let {
            RomanListWrapper(
                title = Titles.TITLE_4.titleDescription,
                list = it
            )
        }

        ESGTextAreaFieldWrapper {
            name = props.section3_3_A4_name
            value = props.section3_3_A4_value
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }

    Box {

        Titles.TITLE_5.listTitles?.let {
            RomanListWrapper(
                title = Titles.TITLE_5.titleDescription,
                list = it
            )
        }
        ESGTextAreaFieldWrapper {
            name = props.section3_3_A5_name
            value = props.section3_3_A5_value
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }

    Box {
        label {
            +Titles.TITLE_6.titleDescription
        }
        ESGTextAreaFieldWrapper {
            name = props.section3_3_A6_name
            value = props.section3_3_A6_value
            onBlur = { event -> onBlurTextArea(event, null, null) }
            onChange = { event -> onChangeTextArea(event, null, null) }
        }
    }


}

fun ChildrenBuilder.LabelListWrapper(
    inOlType: OlType,
    title: String,
    list: List<String>
): Unit = label {
    +title

    ol {
        type = inOlType

        list.forEach { listItem ->
            li {
                +listItem
            }
        }
    }
}

fun ChildrenBuilder.RomanListWrapper (
    title: String,
    list: List<String>
): Unit = LabelListWrapper(
    inOlType = OlType.i,
    title = title,
    list = list
)