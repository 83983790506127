package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_6 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        SectionMainTitle {
            title = "403-6 PROMOTION OF WORKER HEALTH"
        }
        Box {
            label {
                +"An explanation of how the organization facilitates workers’ access to non-occupational medical and "
                +"healthcare services, and the scope of access provided."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4036A1.name
                value = state.section4036A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"A description of any voluntary health promotion services and programs offered to workers to address "
                +"major non-work-related health risks, including the specific health risks addressed, and how the organization "
                +"facilitates workers’ access to these services and programs."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4036A2.name
                value = state.section4036A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}