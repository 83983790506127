package com.ecosave.watch.portal.components.esg.forcedorcompulsorylabor

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.models.esg.ForcedOrCompulsoryLaborIncident
import com.ecosave.watch.portal.models.esg.ForcedOrCompulsoryLaborState
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange


val GRI_409_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val listName = ForcedOrCompulsoryLaborState::forcedOrCompulsoryLaborList.name

    props.esgReportState.report?.forcedOrCompulsoryLabor?.let { state ->
        val list = state.forcedOrCompulsoryLaborList

        SectionMainTitle {
            title = "409-1 OPERATIONS AND SUPPLIER AT SIGNIFICANT RISK FOR INCIDENTS OF FORCED OR COMPULSORY LABOR"
        }
        SectionSubHeading {
            subHeading =
                "Operations and suppliers considered to have significant risk for incidents of forced or compulsory labor either in terms of:"
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(listName)
                }
                items = list
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TWO_COLUMN_TABLE_4_8.cssClass
                    TableHeader {
                        header = "Type of operation and supplier"
                    }
                    TableHeader {
                        header = "Countries or geographic areas with operations and suppliers considered at risk."
                    }
                }
            }

            list.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(listName, index)
                    }
                    items = list
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE

                    Box {
                        className = ESGCommonStyles.TWO_COLUMN_TABLE_4_8.cssClass
                        ESGTextAreaFieldWrapper {
                            name = ForcedOrCompulsoryLaborIncident::section4091A1.name
                            value = it.section4091A1
                            onChange = { event -> onChangeTextArea(event, index, listName) }
                            onBlur = { event -> onBlurTextArea(event, index, listName) }
                            minimumRows = 1
                        }

                        ESGTextAreaFieldWrapper {
                            name = ForcedOrCompulsoryLaborIncident::section4091A2.name
                            value = it.section4091A2
                            onChange = { event -> onChangeTextArea(event, index, listName) }
                            onBlur = { event -> onBlurTextArea(event, index, listName) }
                            minimumRows = 1
                        }
                    }
                }
            }
        }

        Box {
            ReactHTML.label {
                className = ESGCommonStyles.LABEL.cssClass
                +"What are the measures taken by the organization in the reporting period intended to contribute to the elimination of all forms of forced or compulsory labor?"
            }
            ESGTextAreaFieldWrapper {
                name = ForcedOrCompulsoryLaborState::section4091Field1.name
                value = state.section4091Field1
                onBlur = { event -> onBlurTextArea(event, null, null) }
                onChange = { event -> onChangeTextArea(event, null, null) }
            }
        }
    }

}