package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_9 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        SectionMainTitle {
            title = "403-9 WORK-RELATED INJURIES"
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass

            TableHeader {
                header = "Employees"
            }
            TableHeader {
                header = "Number"
            }
            TableHeader {
                header = "Rate"
            }

            label {
                +"Fatalities as a result of work-related injury"
            }
            ESGNumberFieldWrapper {
                name = state::section4039Number1.name
                value = state.section4039Number1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4039Rate1.name
                value = state.section4039Rate1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"High-consequence work-related injuries"
            }
            ESGNumberFieldWrapper {
                name = state::section4039Number2.name
                value = state.section4039Number2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4039Rate2.name
                value = state.section4039Rate2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Recordable work-related injuries"
            }
            ESGNumberFieldWrapper {
                name = state::section4039Number3.name
                value = state.section4039Number3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4039Rate3.name
                value = state.section4039Rate3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Main types of work-related injuries "
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039Main1.name
                value = state.section4039Main1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Number of hours worked"
            }
            ESGNumberFieldWrapper {
                name = state::section4039NumberOfHours1.name
                value = state.section4039NumberOfHours1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass

            TableHeader {
                header = "Workers who are not employees"
            }
            TableHeader {
                header = "Number"
            }
            TableHeader {
                header = "Rate"
            }

            label {
                +"Fatalities as a result of work-related injuries"
            }
            ESGNumberFieldWrapper {
                name = state::section4039Number4.name
                value = state.section4039Number4
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4039Rate4.name
                value = state.section4039Rate4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"High-consequence work-related injuries"
            }
            ESGNumberFieldWrapper {
                name = state::section4039Number5.name
                value = state.section4039Number5
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4039Rate5.name
                value = state.section4039Rate5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }

            label {
                +"Recordable work-related injuries"
            }
            ESGNumberFieldWrapper {
                name = state::section4039Number6.name
                value = state.section4039Number6
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4039Rate6.name
                value = state.section4039Rate6
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        Box {
            label {
                +"Main types of work-related injuries"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039Main2.name
                value = state.section4039Main2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.TWO_COLUMN_TABLE_8_4.cssClass

            label {
                +"Number of hours worked"
            }
            ESGNumberFieldWrapper {
                name = state::section4039NumberOfHours2.name
                value = state.section4039NumberOfHours2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }

        Box {
            label {
                +"What was the hazard that posed a risk of high-consequence injury?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039A1.name
                value = state.section4039A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"How these hazards have been determined?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039A2.name
                value = state.section4039A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Which of these hazards have caused or contributed to high-consequence injuries during the reporting period?"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039A3.name
                value = state.section4039A3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Actions taken or underway to eliminate these hazards and minimize risks using the hierarchy of controls"
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039Field1.name
                value = state.section4039Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Whether the rates have been calculated based on 200,000 or 1,000,000 hours worked."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039Field2.name
                value = state.section4039Field2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039Field3.name
                value = state.section4039Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Any contextual information necessary to understand how the data have been compiled, such as any "
                +"standards, methodologies, and assumptions used."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4039Field4.name
                value = state.section4039Field4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}