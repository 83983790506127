package com.ecosave.watch.portal.components.esg.economicperformance

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.EconomicPerformanceStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_201_4 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.economicPerformance?.let { state ->
        val countriesAndValuesListName = state::countriesAndValuesList.name

        SectionMainTitle {
            title = "201-4 FINANCIAL ASSISTANCE RECEIVED FROM GOVERNMENT"
        }
        SectionSubHeading {
            subHeading =
                "Please complete the following table to report the total monetary value of financial assistance received during the reporting period. Where possible, please categorize the assistance and specify the providing government or agency."
        }
        Box {
            className = EconomicPerformanceStyles.SECTION_201_4_TABLE.cssClass
            TableHeader {
                header = "Type Of Assistance"
            }
            TableHeader {
                header = "Monetary Value"
            }
            Label {
                label = "Tax relief and tax credits."
            }
            ESGNumberFieldWrapper {
                name = state::section2014A1.name
                value = state.section2014A1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Subsidies"
            }
            ESGNumberFieldWrapper {
                name = state::section2014A2.name
                value = state.section2014A2
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Investment grants, research and development grants, and other relevant types of grants."
            }
            ESGNumberFieldWrapper {
                name = state::section2014A3.name
                value = state.section2014A3
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Awards"
            }
            ESGNumberFieldWrapper {
                name = state::section2014A4.name
                value = state.section2014A4
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Royalty holidays"
            }
            ESGNumberFieldWrapper {
                name = state::section2014A5.name
                value = state.section2014A5
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Financial assistance from Export Credit Agencies (ECAs)."
            }
            ESGNumberFieldWrapper {
                name = state::section2014A6.name
                value = state.section2014A6
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Financial incentives"
            }
            ESGNumberFieldWrapper {
                name = state::section2014A7.name
                value = state.section2014A7
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
            Label {
                label = "Other financial benefits received or receivable from any government for any operation."
            }
            ESGNumberFieldWrapper {
                name = state::section2014A8.name
                value = state.section2014A8
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
        SectionSubHeading {
            subHeading =
                "Please list the countries in which your organization received financial assistance during the reporting period. For each country, indicate the total monetary value of assistance received."
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(countriesAndValuesListName)
                }
                items = state.countriesAndValuesList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = EconomicPerformanceStyles.SECTION_201_4_TABLE_2.cssClass
                    TableHeader {
                        header = "Country"
                    }
                    TableHeader {
                        header = "Tax relief and tax credits"
                    }
                    TableHeader {
                        header = "Subsidies"
                    }
                    TableHeader {
                        header = "Grants"
                    }
                    TableHeader {
                        header = "Awards"
                    }
                    TableHeader {
                        header = "Royalty holidays"
                    }
                    TableHeader {
                        header = "Financial assistance (ECA)"
                    }
                    TableHeader {
                        header = "Financial Incentives"
                    }
                    TableHeader {
                        header = "Other financial benefits"
                    }
                }
            }
            state.countriesAndValuesList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(countriesAndValuesListName, index)
                    }
                    items = state.countriesAndValuesList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = EconomicPerformanceStyles.SECTION_201_4_TABLE_2.cssClass
                        ESGTextFieldWrapper {
                            name = it::section2014Part2A1.name
                            value = it.section2014Part2A1
                            onChange =
                                { event -> onChangeText(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurText(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A2.name
                            value = it.section2014Part2A2
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A3.name
                            value = it.section2014Part2A3
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A4.name
                            value = it.section2014Part2A4
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A5.name
                            value = it.section2014Part2A5
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A6.name
                            value = it.section2014Part2A6
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A7.name
                            value = it.section2014Part2A7
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A8.name
                            value = it.section2014Part2A8
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = it::section2014Part2A9.name
                            value = it.section2014Part2A9
                            onChange =
                                { event -> onChangeDecimalNumber(event, index, countriesAndValuesListName) }
                            onBlur = { event -> onBlurDecimalNumber(event, index, countriesAndValuesListName) }
                        }
                    }
                }
            }
        }

        Box {
            Label {
                label = "Whether, and the extent to which, any government is present in the shareholding structure."
            }
            ESGTextAreaFieldWrapper {
                name = state::section2014Part2Field1.name
                value = state.section2014Part2Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}