package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.uuid.UUID
import kotlinx.uuid.generateUUID
import kotlin.random.Random

@Serializable
data class PublicPolicyState(
    var sectionPublicPolicyList: MutableList<SectionPublicPolicy> = mutableListOf(),

    var section3_3_415A1: String? = null,
    var section3_3_415A2: String? = null,
    var section3_3_415A3: String? = null,
    var section3_3_415A4: String? = null,
    var section3_3_415A5: String? = null,
    var section3_3_415A6: String? = null
)

@Serializable
data class SectionPublicPolicy(
    @Transient
    val key: String = UUID.generateUUID(Random).toString(),
    var section4151A1: String? = null,
    var section4151A2: String? = null,
    var section4151A3: Long? = null,
    var section4151A4: String? = null
)