package com.ecosave.watch.portal.components.esg.suppliersocialassessment

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_414_2 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.supplierSocialAssessment?.let { state ->
        SectionMainTitle {
            title = "414-2 NEGATIVE SOCIAL IMPACTS IN THE SUPPLY CHAIN AND ACTIONS TAKEN"
        }

        Box {
            className = ESGCommonStyles.GRID_WRAPPER.cssClass

            Box {
                label {
                    +"Number of suppliers assessed for social impacts."
                }
                ESGNumberFieldWrapper {
                    name = state::section4142A1.name
                    value = state.section4142A1
                    onChange = { event -> onChangeNumber(event, null, null) }
                    onBlur = { event -> onBlurNumber(event, null, null) }
                }
            }
            Box {
                label {
                    +"Number of suppliers identified as having significant actual and potential negative social impacts."
                }
                ESGNumberFieldWrapper {
                    name = state::section4142A2.name
                    value = state.section4142A2
                    onChange = { event -> onChangeNumber(event, null, null) }
                    onBlur = { event -> onBlurNumber(event, null, null) }
                }
            }
            Box {
                label {
                    +"What are the significant actual and potential negative social impacts identified in the supply chain?"
                }
                ESGTextAreaFieldWrapper {
                    name = state::section4142A3.name
                    value = state.section4142A3
                    onChange = { event -> onChangeTextArea(event, null, null) }
                    onBlur = { event -> onBlurTextArea(event, null, null) }
                }
            }
            Box {
                label {
                    +"Percentage of suppliers identified as having significant actual and potential negative social impacts with which improvements were agreed upon as a result of assessment."
                }
                ESGNumberFieldWrapper {
                    name = state::section4142A4.name
                    value = state.section4142A4
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }
            Box {
                label {
                    +"Percentage of suppliers identified as having significant actual and potential negative social impacts with which relationships were terminated as a result of assessment, and why?"
                }
                ESGNumberFieldWrapper {
                    name = state::section4142A5.name
                    value = state.section4142A5
                    onChange = { event -> onChangeDecimalNumber(event, null, null) }
                    onBlur = { event -> onBlurDecimalNumber(event, null, null) }
                }
            }
        }
    }
}