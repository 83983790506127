package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.ConfirmationDialog
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import emotion.react.css
import mui.icons.material.Add
import mui.icons.material.Delete
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.Tooltip
import react.FC
import react.PropsWithChildren
import react.ReactNode
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Visibility
import web.cssom.number
import web.cssom.px

external interface DynamicTableWrapperProps : PropsWithChildren {
    var addRowOrSection: () -> Unit?
    var deleteRowOrSection: () -> Unit?
    var items: MutableList<dynamic>
    var action: DynamicTableAction
    var variant: DynamicTableVariant?
    var addRowOrSectionBtnText: String?
}

val DynamicTableWrapper = FC<DynamicTableWrapperProps> { props ->

    val dynamicTableVariant = props.variant ?: DynamicTableVariant.LIST
    val addRowOrSectionBtnText = props.addRowOrSectionBtnText ?: "row"
    var showDeleteConfirmationDialog by useState(false)

    Box {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            gap = 5.px
        }
        Box {
            css {
                flexGrow = number(1.0)
            }
            +props.children
        }
        if (props.action === DynamicTableAction.ADD) {
            Box {
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                }
                if (dynamicTableVariant == DynamicTableVariant.TABLE) {
                    Button {
                        variant = ButtonVariant.contained
                        css {
                            margin = 20.px
                        }
                        +addRowOrSectionBtnText
                        onClick = {
                            props.addRowOrSection()
                        }
                    }
                }
                if (dynamicTableVariant == DynamicTableVariant.LIST) {
                    Tooltip {
                        title = ReactNode(addRowOrSectionBtnText)
                        IconButton {
                            onClick = {
                                props.addRowOrSection()
                            }
                            Add()
                        }
                    }
                }
            }
        }
        if (dynamicTableVariant == DynamicTableVariant.LIST && props.action === DynamicTableAction.DELETE && props.items.size > 1) {
            Box {
                css {
                    display = Display.flex
                    alignItems = AlignItems.flexStart
                }
                Tooltip {
                    title = ReactNode("Delete this section")
                    IconButton {
                        onClick = {
                            showDeleteConfirmationDialog = true
                        }
                        Delete()
                    }
                }
            }
        }
        if (dynamicTableVariant == DynamicTableVariant.TABLE && props.action === DynamicTableAction.DELETE) {
            Box {
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                    visibility = if (props.items.size > 1) Visibility.visible else Visibility.hidden
                }
                Tooltip {
                    title = ReactNode("Delete this row")
                    IconButton {
                        onClick = {
                            showDeleteConfirmationDialog = true
                        }
                        Delete()
                    }
                }
            }
        }
    }
    ConfirmationDialog {
        open = showDeleteConfirmationDialog
        title = if (dynamicTableVariant == DynamicTableVariant.LIST) "Delete Section" else "Delete Row"
        body = "Are you sure you want to delete this ${if (dynamicTableVariant == DynamicTableVariant.LIST) "section" else "row"}?"
        actionResult = { value ->
            if (value) {
                props.deleteRowOrSection()
            }
            showDeleteConfirmationDialog = false
        }
    }
}