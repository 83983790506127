package com.ecosave.watch.portal.components.esg.customerprivacy

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.Label
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_418_1 = FC<EsgSubFormComponentProps> { props ->

    val onChangeNumber = props.onChangeNumber
    val onChangeTextArea = props.onChangeTextArea
    val onBlurNumber = props.onBlurNumber
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.customerPrivacy?.let { state ->
        SectionMainTitle {
            title = "418-1 SUBSTANTIATED COMPLAINTS CONCERNING BREACHES OF CUSTOMER PRIVACY AND LOSSES OF CUSTOMER DATA"
        }

        Box {
            className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
            TableHeader {
                header = ""
            }
            TableHeader {
                header = "Current Year"
            }
            TableHeader {
                header = "Previous Year"
            }
            Label {
                label =
                    "Total number of complaints concerning breaches of customer privacy - Complaints received from outside."
            }
            ESGNumberFieldWrapper {
                name = state::section4181A1.name
                value = state.section4181A1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4181B1.name
                value = state.section4181B1
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label =
                    "Total number of complaints concerning breaches of customer privacy - Complaints from regulatory bodies."
            }
            ESGNumberFieldWrapper {
                name = state::section4181A2.name
                value = state.section4181A2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4181B2.name
                value = state.section4181B2
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label = "Total number of identified leaks, thefts, or losses of customer data."
            }
            ESGNumberFieldWrapper {
                name = state::section4181A3.name
                value = state.section4181A3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            ESGNumberFieldWrapper {
                name = state::section4181B3.name
                value = state.section4181B3
                onChange = { event -> onChangeNumber(event, null, null) }
                onBlur = { event -> onBlurNumber(event, null, null) }
            }
            Label {
                label =
                    "If the organization has not identified any substantiated complaints, a brief statement of this fact is sufficient."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4181A4.name
                value = state.section4181A4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
            ESGTextAreaFieldWrapper {
                name = state::section4181B4.name
                value = state.section4181B4
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}