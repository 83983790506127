package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.ReportConstants.ADD_ROW_MESSAGE
import com.ecosave.watch.portal.helpers.esg.ReportConstants.TABLE_TEXT_AREA_ROW_LENGTH
import com.ecosave.watch.portal.models.esg.Section4031
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.onChange

val GRI_403_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        val section4031ListName = state::section4031List.name

        SectionMainTitle {
            title = "403-1 OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT SYSTEM"
        }
        Box {
            label {
                +"A statement of whether an occupational health and safety management "
                +"system has been implemented, including whether:"

                ol {
                    li {
                        +"the system has been implemented because of legal requirements and, if so, a list of the requirements."
                    }
                    li {
                        +"the system has been implemented based on recognized risk management and/or management system "
                        +"standards/guidelines and, if so, a list of the standards/guidelines."
                    }
                }
            }
            ESGTextAreaFieldWrapper {
                name = state::section4031Field1.name
                value = state.section4031Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = {
                    props.addRowOrSection(section4031ListName)
                }
                items = state.section4031List
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.TABLE_GRID_WRAPPER.cssClass
                    TableHeader {
                        header =
                            "Requirements for occupational health and safety management system OR management system standards/guidelines"
                    }
                }
            }
            state.section4031List.forEachIndexed { index, item ->
                DynamicTableWrapper {
                    key = item.key
                    deleteRowOrSection = {
                        props.deleteRowOrSection(section4031ListName, index)
                    }
                    items = state.section4031List
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.TABLE_GRID_WRAPPER.cssClass
                        ESGTextAreaFieldWrapper {
                            name = Section4031::section4031Field2.name
                            value = item.section4031Field2
                            onChange = { event -> onChangeTextArea(event, index, section4031ListName) }
                            onBlur = { event -> onBlurTextArea(event, index, section4031ListName) }
                            minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                            maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
                        }
                    }
                }
            }
        }
        Box {
            label {
                +"A description of the scope of workers, activities, and workplaces covered "
                +"by the occupational health and safety management system, and an "
                +"explanation of whether and, if so, why any workers, activities, or "
                +"workplaces are not covered."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4031Field3.name
                value = state.section4031Field3
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
                minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
            }
        }
    }
}