package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgCoreComponentProps
import com.ecosave.watch.portal.components.esg.GenericSection_3_3
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.common.isValidDecimalNumber
import com.ecosave.watch.portal.helpers.common.isValidLongNumber
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.helpers.esg.failureErrorMessage
import com.ecosave.watch.portal.helpers.esg.omitDisclosureFromUI
import com.ecosave.watch.portal.helpers.esg.resetPreviousPatchState
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.OccupationalHealthAndSafetyState
import com.ecosave.watch.portal.models.esg.RowPatch
import com.ecosave.watch.portal.models.esg.Section4031
import com.ecosave.watch.portal.models.esg.Section4033
import com.ecosave.watch.portal.services.esg.addDynamicRowOrSection
import com.ecosave.watch.portal.services.esg.deleteDynamicRowOrSection
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Box
import react.FC
import react.dom.events.FocusEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement

val OccupationHealthAndSafetyComponent = FC<EsgCoreComponentProps> { props ->
    val reportState = props.reportState
    val setReportState = props.setReportState

    reportState.report?.occupationalHealthAndSafety?.let { state ->
        fun stateSetter(state: OccupationalHealthAndSafetyState) {
            setReportState(
                reportState.copy(
                    report = reportState.report.copy(
                        occupationalHealthAndSafety = state
                    )
                )
            )
        }

        val addRowOrSection: (String) -> Unit = { fieldName ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.ADD)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = addDynamicRowOrSection(
                    EsgCollectionAddRow(
                        objectName = EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name,
                        reportFileName = reportState.reportFileName,
                        patchCollection = RowPatch(
                            pathFirst = fieldName
                        )
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        OccupationalHealthAndSafetyState::section4031List.name -> {
                            newState.section4031List.add(Section4031())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }

                        OccupationalHealthAndSafetyState::section4033List.name -> {
                            newState.section4033List.add(Section4033())
                            props.setNotificationMessage(ReportConstants.ADD_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val deleteRowOrSection: (String, Int) -> Unit = { fieldName, index ->
            resetPreviousPatchState(props.setInputState)
            mainScope.launch {
                props.setAddDeleteRowOrSection(DynamicTableAction.DELETE)
                props.setAddDeleteRowOrSectionApiCallInProgress(true)
                val apiCallStatus = deleteDynamicRowOrSection(
                    EsgCollectionDeleteRow(
                        objectPatchEnum = EsgSection.OCCUPATION_HEALTH_AND_SAFETY,
                        reportFileName = reportState.reportFileName,
                        pathFirst = fieldName,
                        indexAt = index
                    )
                )
                if (apiCallStatus == ApiCallStatus.SUCCESS) {
                    val newState = state.copy()

                    when (fieldName) {
                        OccupationalHealthAndSafetyState::section4031List.name -> {
                            newState.section4031List.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }

                        OccupationalHealthAndSafetyState::section4033List.name -> {
                            newState.section4033List.removeAt(index)
                            props.setNotificationMessage(ReportConstants.DELETE_ROW_NOTIFICATION_MESSAGE)
                        }
                    }

                    stateSetter(newState)

                    props.setNotificationStatus(NotificationStatus.SUCCESS)
                    props.setOpenAlertNotifications(true)
                } else {
                    failureErrorMessage(
                        props.setNotificationMessage,
                        props.setNotificationStatus,
                        props.setOpenAlertNotifications
                    )
                }
                props.setAddDeleteRowOrSectionApiCallInProgress(false)
            }
        }

        val onChangeDecimalNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section4038B1.name -> newState.section4038B1 = propertyValue
                    state::section4038B2.name -> newState.section4038B2 = propertyValue
                    state::section4038B3.name -> newState.section4038B3 = propertyValue
                    state::section4039Rate1.name -> newState.section4039Rate1 = propertyValue
                    state::section4039Rate2.name -> newState.section4039Rate2 = propertyValue
                    state::section4039Rate3.name -> newState.section4039Rate3 = propertyValue
                    state::section4039Rate4.name -> newState.section4039Rate4 = propertyValue
                    state::section4039Rate5.name -> newState.section4039Rate5 = propertyValue
                    state::section4039Rate6.name -> newState.section4039Rate6 = propertyValue
                    state::section4039NumberOfHours1.name -> newState.section4039NumberOfHours1 = propertyValue
                    state::section4039NumberOfHours2.name -> newState.section4039NumberOfHours2 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurDecimalNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidDecimalNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toDoubleOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name
                )
            }
        }

        val onChangeNumber: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()

                val newState = state.copy()

                when (propertyName) {
                    state::section4038A1.name -> newState.section4038A1 = propertyValue
                    state::section4038A2.name -> newState.section4038A2 = propertyValue
                    state::section4038A3.name -> newState.section4038A3 = propertyValue
                    state::section4039Number1.name -> newState.section4039Number1 = propertyValue
                    state::section4039Number2.name -> newState.section4039Number2 = propertyValue
                    state::section4039Number3.name -> newState.section4039Number3 = propertyValue
                    state::section4039Number4.name -> newState.section4039Number4 = propertyValue
                    state::section4039Number5.name -> newState.section4039Number5 = propertyValue
                    state::section4039Number6.name -> newState.section4039Number6 = propertyValue
                    state::section40310Number1.name -> newState.section40310Number1 = propertyValue
                    state::section40310Number2.name -> newState.section40310Number2 = propertyValue
                    state::section40310Number3.name -> newState.section40310Number3 = propertyValue
                    state::section40310Number4.name -> newState.section40310Number4 = propertyValue
                    state::section40310Number5.name -> newState.section40310Number5 = propertyValue
                    state::section40310Number6.name -> newState.section40310Number6 = propertyValue
                }

                stateSetter(newState)

                props.setInputState(
                    CurrentInputState(
                        parentPath = field,
                        childPath = propertyName,
                        index = changeIndex,
                        value = JsonPrimitive(propertyValue),
                        objectName = EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name,
                        reportName = reportState.reportFileName
                    )
                )
            }
        }

        val onBlurNumber: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLInputElement
            if (isValidLongNumber(target)) {
                val propertyName = target.name
                val propertyValue = target.value.toLongOrNull()
                props.onBlurApiCall(
                    propertyName,
                    JsonPrimitive(propertyValue),
                    changeIndex,
                    field,
                    EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name
                )
            }
        }

        val onChangeTextArea: (FormEvent<HTMLDivElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            val newState = state.copy()

            if (field == OccupationalHealthAndSafetyState::section4031List.name && changeIndex != null) {
                newState.section4031List[changeIndex].let {
                    when (propertyName) {
                        Section4031::section4031Field2.name -> it.section4031Field2 = propertyValue
                    }
                }
            } else if (field == OccupationalHealthAndSafetyState::section4033List.name && changeIndex != null) {
                newState.section4033List[changeIndex].let {
                    when (propertyName) {
                        Section4033::section4033A1.name -> it.section4033A1 = propertyValue
                        Section4033::section4033A2.name -> it.section4033A2 = propertyValue
                        Section4033::section4033A3.name -> it.section4033A3 = propertyValue
                    }
                }
            } else {
                when (propertyName) {
                    state::section4031Field1.name -> newState.section4031Field1 = propertyValue
                    state::section4031Field3.name -> newState.section4031Field3 = propertyValue

                    state::section4032A1.name -> newState.section4032A1 = propertyValue
                    state::section4032A2.name -> newState.section4032A2 = propertyValue
                    state::section4032A3.name -> newState.section4032A3 = propertyValue
                    state::section4032A4.name -> newState.section4032A4 = propertyValue
                    state::section4032A5.name -> newState.section4032A5 = propertyValue
                    state::section4032A6.name -> newState.section4032A6 = propertyValue

                    state::section4034A1.name -> newState.section4034A1 = propertyValue
                    state::section4034A2.name -> newState.section4034A2 = propertyValue

                    state::section4035Field1.name -> newState.section4035Field1 = propertyValue

                    state::section4036A1.name -> newState.section4036A1 = propertyValue
                    state::section4036A2.name -> newState.section4036A2 = propertyValue

                    state::section4037Field1.name -> newState.section4037Field1 = propertyValue

                    state::section4038Why.name -> newState.section4038Why = propertyValue
                    state::section4038Field1.name -> newState.section4038Field1 = propertyValue

                    state::section4039Main1.name -> newState.section4039Main1 = propertyValue
                    state::section4039Main2.name -> newState.section4039Main2 = propertyValue
                    state::section4039A1.name -> newState.section4039A1 = propertyValue
                    state::section4039A2.name -> newState.section4039A2 = propertyValue
                    state::section4039A3.name -> newState.section4039A3 = propertyValue
                    state::section4039Field1.name -> newState.section4039Field1 = propertyValue
                    state::section4039Field2.name -> newState.section4039Field2 = propertyValue
                    state::section4039Field3.name -> newState.section4039Field3 = propertyValue
                    state::section4039Field4.name -> newState.section4039Field4 = propertyValue

                    state::section40310A1.name -> newState.section40310A1 = propertyValue
                    state::section40310A2.name -> newState.section40310A2 = propertyValue
                    state::section40310A3.name -> newState.section40310A3 = propertyValue
                    state::section40310Field1.name -> newState.section40310Field1 = propertyValue
                    state::section40310Field2.name -> newState.section40310Field2 = propertyValue
                    state::section40310Field3.name -> newState.section40310Field3 = propertyValue

                    state::section3_3_403A1.name -> newState.section3_3_403A1 = propertyValue
                    state::section3_3_403A2.name -> newState.section3_3_403A2 = propertyValue
                    state::section3_3_403A3.name -> newState.section3_3_403A3 = propertyValue
                    state::section3_3_403A4.name -> newState.section3_3_403A4 = propertyValue
                    state::section3_3_403A5.name -> newState.section3_3_403A5 = propertyValue
                    state::section3_3_403A6.name -> newState.section3_3_403A6 = propertyValue
                }
            }

            stateSetter(newState)

            props.setInputState(
                CurrentInputState(
                    parentPath = field,
                    childPath = propertyName,
                    index = changeIndex,
                    value = JsonPrimitive(propertyValue),
                    objectName = EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name,
                    reportName = reportState.reportFileName
                )
            )
        }

        val onBlurTextArea: (FocusEvent<HTMLElement>, Int?, String?) -> Unit = { event, changeIndex, field ->
            val target = event.target as HTMLTextAreaElement
            val propertyName = target.name
            val propertyValue = target.value

            props.onBlurApiCall(
                propertyName,
                JsonPrimitive(propertyValue),
                changeIndex,
                field,
                EsgSection.OCCUPATION_HEALTH_AND_SAFETY.name
            )
        }

        Box {
            className = ESGCommonStyles.SECTION_MAIN_LAYOUT.cssClass

            GenericSection_3_3 {
                this.onBlurTextArea = onBlurTextArea
                this.onChangeTextArea = onChangeTextArea
                this.materialTopicName = EsgSection.OCCUPATION_HEALTH_AND_SAFETY.description

                this.section3_3_A1_name = state::section3_3_403A1.name
                this.section3_3_A1_value = state.section3_3_403A1

                this.section3_3_A2_name = state::section3_3_403A2.name
                this.section3_3_A2_value = state.section3_3_403A2

                this.section3_3_A3_name = state::section3_3_403A3.name
                this.section3_3_A3_value = state.section3_3_403A3

                this.section3_3_A4_name = state::section3_3_403A4.name
                this.section3_3_A4_value = state.section3_3_403A4

                this.section3_3_A5_name = state::section3_3_403A5.name
                this.section3_3_A5_value = state.section3_3_403A5

                this.section3_3_A6_name = state::section3_3_403A6.name
                this.section3_3_A6_value = state.section3_3_403A6
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_1)) {
                GRI_403_1 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_2)) {
                GRI_403_2 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_3)) {
                GRI_403_3 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.addRowOrSection = addRowOrSection
                    this.deleteRowOrSection = deleteRowOrSection
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_4)) {
                GRI_403_4 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_5)) {
                GRI_403_5 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_6)) {
                GRI_403_6 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_7)) {
                GRI_403_7 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_8)) {
                GRI_403_8 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_9)) {
                GRI_403_9 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                    this.onChangeDecimalNumber = onChangeDecimalNumber
                    this.onBlurDecimalNumber = onBlurDecimalNumber
                }
            }
            if (omitDisclosureFromUI(reportState.omittedDisclosuresList, GriDisclosureTitles.GRI_403_10)) {
                GRI_403_10 {
                    this.esgReportState = reportState
                    this.onChangeTextArea = onChangeTextArea
                    this.onBlurTextArea = onBlurTextArea
                    this.onChangeNumber = onChangeNumber
                    this.onBlurNumber = onBlurNumber
                }
            }
        }
    }
}