package com.ecosave.watch.portal.components.esg.publicpolicy

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.SectionSubHeading
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import com.ecosave.watch.portal.models.esg.PublicPolicyState
import com.ecosave.watch.portal.models.esg.SectionPublicPolicy
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import com.ecosave.watch.portal.styles.esg.ESGFormStyles
import mui.material.Box
import react.FC
import react.dom.onChange

val GRI_415_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeNumber = props.onChangeNumber
    val onBlurNumber = props.onBlurNumber
    val onChangeText = props.onChangeText
    val onBlurText = props.onBlurText
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.publicPolicy?.let { state ->
        val sectionPublicPolicyListName = PublicPolicyState::sectionPublicPolicyList.name

        SectionMainTitle {
            title = "415-1 POLITICAL CONTRIBUTIONS"
        }

        SectionSubHeading {
            subHeading =
                "What is the total monetary value of financial and in-kind political contributions made both directly and indirectly to recipients/beneficiaries?"
        }

        Box {
            className = ESGCommonStyles.TABLE_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(sectionPublicPolicyListName) }
                items = state.sectionPublicPolicyList
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ReportConstants.ADD_ROW_MESSAGE
                Box {
                    className = ESGFormStyles.SECTION_415_1_TABLE.cssClass
                    TableHeader {
                        header = "Country"
                    }
                    TableHeader {
                        header = "Recipient"
                    }
                    TableHeader {
                        header = "Total Monetary Value"
                    }
                    TableHeader {
                        header = "If applicable, how the monetary value of in-kind contributions was estimated."
                    }
                }
            }
            state.sectionPublicPolicyList.forEachIndexed { index, it ->
                DynamicTableWrapper {
                    key = it.key
                    deleteRowOrSection = { props.deleteRowOrSection(sectionPublicPolicyListName, index) }
                    items = state.sectionPublicPolicyList
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGFormStyles.SECTION_415_1_TABLE.cssClass
                        ESGTextFieldWrapper {
                            name = SectionPublicPolicy::section4151A1.name
                            value = it.section4151A1
                            onChange = { event ->
                                onChangeText(event, index, sectionPublicPolicyListName)
                            }
                            onBlur =
                                { event -> onBlurText(event, index, sectionPublicPolicyListName) }
                        }
                        ESGTextFieldWrapper {
                            name = SectionPublicPolicy::section4151A2.name
                            value = it.section4151A2
                            onChange = { event -> onChangeText(event, index, sectionPublicPolicyListName) }
                            onBlur = { event -> onBlurText(event, index, sectionPublicPolicyListName) }
                        }
                        ESGNumberFieldWrapper {
                            name = SectionPublicPolicy::section4151A3.name
                            value = it.section4151A3
                            onChange = { event -> onChangeNumber(event, index, sectionPublicPolicyListName) }
                            onBlur = { event -> onBlurNumber(event, index, sectionPublicPolicyListName) }
                        }
                        ESGTextAreaFieldWrapper {
                            name = SectionPublicPolicy::section4151A4.name
                            value = it.section4151A4
                            onChange = { event -> onChangeTextArea(event, index, sectionPublicPolicyListName) }
                            onBlur = { event -> onBlurTextArea(event, index, sectionPublicPolicyListName) }
                            minimumRows = 1
                            maximumRows = 4
                        }
                    }
                }
            }
        }
    }
}