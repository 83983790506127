package com.ecosave.watch.portal.components.esg.suppliersocialassessment

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGNumberFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_414_1 = FC<EsgSubFormComponentProps> { props ->
    val onChangeDecimalNumber = props.onChangeDecimalNumber
    val onBlurDecimalNumber = props.onBlurDecimalNumber

    props.esgReportState.report?.supplierSocialAssessment?.let { state ->
        SectionMainTitle {
            title = "414-1 NEW SUPPLIERS THAT WERE SCREENED USING SOCIAL CRITERIA"
        }

        Box {
            label {
                +"Percentage of new suppliers that were screened using social criteria."
            }
            ESGNumberFieldWrapper {
                name = state::section4141A1.name
                value = state.section4141A1
                onChange = { event -> onChangeDecimalNumber(event, null, null) }
                onBlur = { event -> onBlurDecimalNumber(event, null, null) }
            }
        }
    }
}