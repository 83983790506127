package com.ecosave.watch.portal.helpers.esg

import com.ecosave.watch.portal.helpers.billing.mainScope
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.models.esg.ActivitiesAndWorkersState
import com.ecosave.watch.portal.models.esg.AntiCompetitiveBehaviorState
import com.ecosave.watch.portal.models.esg.ChildLaborState
import com.ecosave.watch.portal.models.esg.CurrentInputState
import com.ecosave.watch.portal.models.esg.CustomerHealthAndSafetyState
import com.ecosave.watch.portal.models.esg.CustomerPrivacyState
import com.ecosave.watch.portal.models.esg.EconomicPerformanceState
import com.ecosave.watch.portal.models.esg.EsgCollectionPatch
import com.ecosave.watch.portal.models.esg.EsgCollectionPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgPatch
import com.ecosave.watch.portal.models.esg.EsgPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgReportData
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.ForcedOrCompulsoryLaborState
import com.ecosave.watch.portal.models.esg.FreedomOfAssociationAndCollectiveBargainingState
import com.ecosave.watch.portal.models.esg.GovernanceState
import com.ecosave.watch.portal.models.esg.IndirectEconomicImpactsState
import com.ecosave.watch.portal.models.esg.LaborOrManagementRelationsState
import com.ecosave.watch.portal.models.esg.LocalCommunityState
import com.ecosave.watch.portal.models.esg.MarketPresenceState
import com.ecosave.watch.portal.models.esg.MarketingAndLabelingState
import com.ecosave.watch.portal.models.esg.OccupationalHealthAndSafetyState
import com.ecosave.watch.portal.models.esg.ProcurementPracticesState
import com.ecosave.watch.portal.models.esg.PublicPolicyState
import com.ecosave.watch.portal.models.esg.ReportCoverInfoState
import com.ecosave.watch.portal.models.esg.ReportFinancialYear
import com.ecosave.watch.portal.models.esg.SecurityPracticesState
import com.ecosave.watch.portal.models.esg.StakeholderEngagementState
import com.ecosave.watch.portal.models.esg.StrategyPoliciesPracticesState
import com.ecosave.watch.portal.models.esg.SupplierSocialAssessmentState
import com.ecosave.watch.portal.models.esg.TaxState
import com.ecosave.watch.portal.models.esg.TheOrgAndItsRepPracticesState
import com.ecosave.watch.portal.services.esg.autoSaveEsg
import com.ecosave.watch.portal.services.esg.autoSaveEsgCollection
import com.ecosave.watch.portal.services.esg.patchEsgReport
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import react.StateSetter

fun esgPatch(path: String, value: JsonPrimitive, objectName: String, reportName: String) {
    val updatedField = EsgPatch(
        op = "UPSERT",
        path = path,
        value = value
    )
    val patch =
        EsgPatchUpdate(objectName, reportName, updatedField)
    mainScope.launch {
        patchEsgReport(patch)
    }
}

suspend fun autoSaveEsgField(path: String, value: JsonPrimitive?, objectName: String, reportName: String): ApiCallStatus {

    val updatedField = EsgPatch(
        op = "UPSERT",
        path = path,
        value = value
    )
    val patch = EsgPatchUpdate(objectName, reportName, updatedField)
    return autoSaveEsg(patch)
}

suspend fun autoSaveEsgCollectionField(
    parentPath: String,
    childPath: String,
    index: Int,
    value: JsonPrimitive?,
    objectName: String,
    reportName: String,
): ApiCallStatus {

    val updatedField = EsgCollectionPatch(
        pathFirst = parentPath,
        pathSecond = childPath,
        index = index,
        value = value
    )
    val patch = EsgCollectionPatchUpdate(objectName, reportName, updatedField)
    return autoSaveEsgCollection(patch)
}

fun savingErrorMessage(
    setNotificationMessage: StateSetter<String>,
    setNotificationStatus: StateSetter<NotificationStatus>,
    setOpenAlertNotifications: StateSetter<Boolean>,
    status: ApiCallStatus?,
) {
    if (status == ApiCallStatus.FAILURE || status == null) {
        setNotificationMessage("error while saving...")
        setNotificationStatus(NotificationStatus.ERROR)
        setOpenAlertNotifications(true)
    }
}

fun failureErrorMessage(
    setNotificationMessage: StateSetter<String>,
    setNotificationStatus: StateSetter<NotificationStatus>,
    setOpenAlertNotifications: StateSetter<Boolean>,
) {
    setNotificationMessage("An error has occurred, please retry.")
    setNotificationStatus(NotificationStatus.ERROR)
    setOpenAlertNotifications(true)
}


fun resetPreviousPatchState(stateSetter: StateSetter<CurrentInputState>) {
    stateSetter(
        CurrentInputState(
            childPath = "",
            value = JsonPrimitive(""),
            objectName = "",
            reportName = ""
        )
    )
}

fun omitDisclosureFromUI(omittedDisclosuresList: List<GriDisclosureTitles>, disclosure: GriDisclosureTitles): Boolean {
    return !(omittedDisclosuresList.contains(disclosure))
}

fun initializeReportState(): EsgReportState {
    return EsgReportState(
        reportFinancialYear = ReportFinancialYear(),
        report = EsgReportData(
            theOrgAndItsRepPractices = TheOrgAndItsRepPracticesState(),
            activitiesAndWorkers = ActivitiesAndWorkersState(),
            governance = GovernanceState(),
            strategyPoliciesPractices = StrategyPoliciesPracticesState(),
            stakeholderEngagement = StakeholderEngagementState(),
            economicPerformance = EconomicPerformanceState(),
            indirectEconomicImpacts = IndirectEconomicImpactsState(),
            procurementPractices = ProcurementPracticesState(),
            antiCompetitiveBehavior = AntiCompetitiveBehaviorState(),
            reportCoverInfo = ReportCoverInfoState(),
            tax = TaxState(),
            occupationalHealthAndSafety = OccupationalHealthAndSafetyState(),
            marketPresence = MarketPresenceState(),
            customerHealthAndSafety = CustomerHealthAndSafetyState(),
            publicPolicy = PublicPolicyState(),
            marketingAndLabeling = MarketingAndLabelingState(),
            customerPrivacy = CustomerPrivacyState(),
            supplierSocialAssessment = SupplierSocialAssessmentState(),
            forcedOrCompulsoryLabor = ForcedOrCompulsoryLaborState(),
            localCommunity = LocalCommunityState(),
            securityPractices = SecurityPracticesState(),
            freedomOfAssociationAndCollectiveBargaining = FreedomOfAssociationAndCollectiveBargainingState(),
            childLabor = ChildLaborState(),
            laborOrManagementRelations = LaborOrManagementRelationsState()
        )
    )
}