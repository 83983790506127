package com.ecosave.watch.portal.helpers.esg

enum class GenericSection3n3TitlesEnum(val titleDescription: String, val listTitles: List<String>? = null) {
    TITLE_1(
        titleDescription = "Describe the actual and potential, negative and positive impacts on the economy, environment," +
            " and people, including impacts on their human rights."
    ),
    TITLE_2(
        titleDescription = "Report whether the organization is involved with the negative impacts through its " +
            "activities or a sa result of its business relationships, and describe the activities or business " +
            "relationships."
    ),
    TITLE_3("Describe its policies or commitments regarding the material topic."),
    TITLE_4(
        titleDescription = "Describe actions taken to manage the topic and related impacts, including:",
        listTitles = listOf(
            "actions to prevent or mitigate potential negative impacts ",
            "actions to address actual negative impacts, including actions to provide for or cooperate in their" +
                " remediation",
            "actions to manage actual and potential positive impacts"
        )
    ),
    TITLE_5(
        titleDescription = "Report the following information about tracking the effectiveness of the actions taken:",
        listTitles = listOf(
            " processes used to track the effectiveness of the actions",
            " goals, targets, and indicators used to evaluate progress",
            " the effectiveness of the actions, including progress toward the goals and targets",
            " lessons learned and how these have been incorporated into the organization’s operational policies and " +
                "procedures",
        )
    ),
    TITLE_6(
        titleDescription = "Describe how engagement with stakeholders has informed the actions taken and how it has " +
            "informed whether the actions have been effective"
    )

}