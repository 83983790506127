package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.DynamicTableWrapper
import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import com.ecosave.watch.portal.components.esg.formcontrols.TableHeader
import com.ecosave.watch.portal.helpers.esg.DynamicTableAction
import com.ecosave.watch.portal.helpers.esg.DynamicTableVariant
import com.ecosave.watch.portal.helpers.esg.ReportConstants.ADD_ROW_MESSAGE
import com.ecosave.watch.portal.helpers.esg.ReportConstants.TABLE_TEXT_AREA_ROW_LENGTH
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_3 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        SectionMainTitle {
            title = "403-3 OCCUPATION HEALTH SERVICES"
        }

        Box {
            label {
                +"A description of the occupational health services’ functions that contribute to the identification and "
                +"elimination of hazards and minimization of risks, and an explanation of how the organization ensures "
                +"the quality of these services and facilitates workers’ access to them."
            }
        }

        Box {
            className = ESGCommonStyles.TABLE_GRID_WRAPPER.cssClass
            DynamicTableWrapper {
                addRowOrSection = { props.addRowOrSection(state::section4033List.name) }
                items = state.section4033List
                action = DynamicTableAction.ADD
                addRowOrSectionBtnText = ADD_ROW_MESSAGE
                Box {
                    className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                    TableHeader {
                        header = "Occupational Health Service"
                    }
                    TableHeader {
                        header = "Description of identification and elimination of hazards and minimization of risks"
                    }
                    TableHeader {
                        header = "How organization ensures the quality of these services and workers’ access them"
                    }
                }
            }
            state.section4033List.forEachIndexed { index, item ->
                DynamicTableWrapper {
                    key = item.key
                    deleteRowOrSection = { props.deleteRowOrSection(state::section4033List.name, index) }
                    items = state.section4033List
                    action = DynamicTableAction.DELETE
                    variant = DynamicTableVariant.TABLE
                    Box {
                        className = ESGCommonStyles.THREE_COLUMN_TABLE.cssClass
                        ESGTextAreaFieldWrapper {
                            name = item::section4033A1.name
                            value = item.section4033A1
                            onChange = { event -> onChangeTextArea(event, index, state::section4033List.name) }
                            onBlur = { event -> onBlurTextArea(event, index, state::section4033List.name) }
                            minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                            maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
                        }

                        ESGTextAreaFieldWrapper {
                            name = item::section4033A2.name
                            value = item.section4033A2
                            onChange = { event -> onChangeTextArea(event, index, state::section4033List.name) }
                            onBlur = { event -> onBlurTextArea(event, index, state::section4033List.name) }
                            minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                            maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
                        }

                        ESGTextAreaFieldWrapper {
                            name = item::section4033A3.name
                            value = item.section4033A3
                            onChange = { event -> onChangeTextArea(event, index, state::section4033List.name) }
                            onBlur = { event -> onBlurTextArea(event, index, state::section4033List.name) }
                            minimumRows = TABLE_TEXT_AREA_ROW_LENGTH
                            maximumRows = TABLE_TEXT_AREA_ROW_LENGTH
                        }
                    }
                }
            }
        }
    }
}