package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_4 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        SectionMainTitle {
            title = "403-4 WORKER PARTICIPATION, CONSULTATION, AND COMMUNICATION ON OCCUPATIONAL HEALTH AND SAFETY"
        }
        Box {
            label {
                +"Describe the processes for worker participation and consultation in the development, implementation, "
                +"and evaluation of the occupational health and safety management system, and for providing access to and "
                +"communicating relevant information on occupation health and safety to workers."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4034A1.name
                value = state.section4034A1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
        Box {
            label {
                +"Specify where a formal joint management-worker health and safety committees exist, and describe their "
                +"responsibilities, meeting frequency, decision-making authority, and whether and, if so, why these "
                +"committees do not represent any workers."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4034A2.name
                value = state.section4034A2
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}