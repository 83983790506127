package com.ecosave.watch.portal.components.esg.occupationhealthandsafety

import com.ecosave.watch.portal.components.esg.EsgSubFormComponentProps
import com.ecosave.watch.portal.components.esg.formcontrols.ESGTextAreaFieldWrapper
import com.ecosave.watch.portal.components.esg.formcontrols.SectionMainTitle
import mui.material.Box
import react.FC
import react.dom.html.ReactHTML.label
import react.dom.onChange

val GRI_403_5 = FC<EsgSubFormComponentProps> { props ->
    val onChangeTextArea = props.onChangeTextArea
    val onBlurTextArea = props.onBlurTextArea

    props.esgReportState.report?.occupationalHealthAndSafety?.let { state ->
        SectionMainTitle {
            title = "403-5 WORKER TRAINING ON OCCUPATIONAL HEALTH AND SAFETY"
        }
        Box {
            label {
                +"A description of any occupational health and safety training provided to workers, including generic "
                +"training as well as training on specific work-related hazards, hazardous activities, or hazardous situations."
            }
            ESGTextAreaFieldWrapper {
                name = state::section4035Field1.name
                value = state.section4035Field1
                onChange = { event -> onChangeTextArea(event, null, null) }
                onBlur = { event -> onBlurTextArea(event, null, null) }
            }
        }
    }
}