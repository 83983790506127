package com.ecosave.watch.portal.helpers.esg

const val debounceDelay: Long = 800

object ReportConstants {
    const val ESG_FINANCIAL_YEAR_FOUR_DIGITS = "This Field must have four digits."
    const val ESG_CALENDAR_YEAR_MAX_DIGITS = 4
    const val TEXT_AREA_MIN_ROWS = 5
    const val TEXT_AREA_MAX_ROWS = 20
    const val TABLE_TEXT_AREA_ROW_LENGTH = 4
    const val DOWNLOAD_SUCCESS = "Download Completed"
    const val DOWNLOAD_FAILED = "Download Failed"
    const val DELETED_SUCCESS = "Successfully Deleted"
    const val DELETED_FAILED = "Deletion Failed"

    const val ADD_ROW_MESSAGE = "Add a new row"
    const val DELETE_ROW_NOTIFICATION_MESSAGE = "Row has been deleted successfully."
    const val DELETE_SECTION_NOTIFICATION_MESSAGE = "Section has been deleted successfully."
    const val ADD_ROW_NOTIFICATION_MESSAGE = "A new row has been added successfully."

    val mandatoryDisclosures = listOf(
        GriDisclosureTitles.GRI_2_1,
        GriDisclosureTitles.GRI_2_2,
        GriDisclosureTitles.GRI_2_3,
        GriDisclosureTitles.GRI_2_4,
        GriDisclosureTitles.GRI_2_5,
        GriDisclosureTitles.GRI_3_1,
        GriDisclosureTitles.GRI_3_2
    )
}

